'use strict';

// add the following
/*<!-- Load c3.css -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/c3/0.4.11/c3.min.css">
<body>
<div id="chart"></div>

<!-- Load d3.js and c3.js -->
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/c3/0.4.11/c3.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.17/d3.min.js"></script>
<script type="text/javascript" src="scripts/donutChart.js">
</body>
*/

window.generateChart = function ( chartJson ) {
  function getCategories( data ) {
     var generatedData = [];
     for(var key in data ) {
       generatedData.push([key, data[key]]);
     }
     return generatedData;
   }

   var chatData = {
     data: {
       columns: getCategories( chartJson.complaint_category ),
       type: 'donut',
       onclick: function (d, i) { console.log('onclick', d, i); },
       onmouseover: function (d, i) { console.log('onmouseover', d, i); },
       onmouseout: function (d, i) { console.log('onmouseout', d, i); }
     },
     donut: {
       title: chartJson.ward
     }
   };

  window.c3.generate(chatData);
};
